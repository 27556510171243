nav .nav-items {
  display: none; }
  nav .nav-items.nav-active {
    display: flex; }

@media screen and (min-width: 992px) {
  nav .nav-items {
    display: flex; } }

@media screen and (max-width: 768px) {
  .primarylogo {
    display: none !important; }
  .mobileLogo {
    display: flex !important; } }

@media screen and (min-width: 768px) {
  .mobileLogo {
    display: none !important; } }

.video-main + .video-main {
  margin-top: 20px; }

.video-main .content {
  position: relative;
  min-height: 560px;
  color: white; }

/* VIDEO BACKGROUND MODULE */
.video-bg {
  position: relative;
  overflow: hidden; }
  .video-bg .video.cover {
    min-height: calc(100% + 2px);
    position: absolute;
    left: -1px;
    right: -1px; }
    .video-bg .video.cover::before {
      content: "";
      display: block;
      height: 0px;
      padding-bottom: 57.14286%; }
    .video-bg .video.cover video {
      width: auto;
      height: 100%;
      position: absolute;
      top: 0px; }
    .video-bg .video.cover.align-left video {
      left: 0px; }
    .video-bg .video.cover.align-center video {
      left: 50%;
      top: 0px;
      transform: translate(-50%, 0%); }
    .video-bg .video.cover.align-right video {
      right: 0px; }
    .video-bg .video.cover img {
      width: auto;
      height: 100%;
      position: absolute;
      max-width: none;
      top: 0px; }
    .video-bg .video.cover.align-left img {
      left: 0px; }
    .video-bg .video.cover.align-center img {
      left: 50%;
      top: 0px;
      transform: translate(-50%, 0%); }
    .video-bg .video.cover.align-right img {
      right: 0px; }
    .video-bg .video.cover.align-top {
      top: 0px; }
    .video-bg .video.cover.align-middle {
      top: 50%;
      transform: translate(0%, -50%); }
    .video-bg .video.cover.align-bottom {
      bottom: 0px; }
  .video-bg .video.contain {
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px; }
    .video-bg .video.contain video {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute; }
    .video-bg .video.contain.align-left video {
      left: 0px; }
    .video-bg .video.contain.align-center video {
      left: 0px;
      right: 0px;
      margin: auto; }
    .video-bg .video.contain.align-right video {
      right: 0px; }
    .video-bg .video.contain.align-top video {
      top: 0px; }
    .video-bg .video.contain.align-middle video {
      top: 0px;
      bottom: 0px;
      margin: auto; }
    .video-bg .video.contain.align-bottom video {
      bottom: 0px; }
    .video-bg .video.contain.fill-width video {
      width: 100%;
      max-height: none; }
    .video-bg .video.contain.fill-height video {
      max-width: none;
      height: 100%; }
    .video-bg .video.contain img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute; }
    .video-bg .video.contain.align-left img {
      left: 0px; }
    .video-bg .video.contain.align-center img {
      left: 0px;
      right: 0px;
      margin: auto; }
    .video-bg .video.contain.align-right img {
      right: 0px; }
    .video-bg .video.contain.align-top img {
      top: 0px; }
    .video-bg .video.contain.align-middle img {
      top: 0px;
      bottom: 0px;
      margin: auto; }
    .video-bg .video.contain.align-bottom img {
      bottom: 0px; }
    .video-bg .video.contain.fill-width img {
      width: 100%;
      max-height: none; }
    .video-bg .video.contain.fill-height img {
      max-width: none;
      height: 100%; }

.box-it {
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 1rem; }

.Countdown-col {
  display: inline-block; }

.Countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column; }

.Countdown-col-element strong {
  font-size: 50px; }

#remainingCardEnvelope {
  background: rgba(105, 100, 100, 0.9); }

.iconbar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999;
  display: grid; }

.iconbar button {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px; }

.sharebutton {
  cursor: pointer; }

.sharebutton:hover:not(:active) {
  opacity: 0.75; }

.social-container {
  padding: 25px 50px; }

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block; }

a.social:hover {
  transform: translateY(-2px); }

a.youtube {
  color: #eb3223; }

a.facebook {
  color: #4968ad; }

a.twitter {
  color: #49a1eb; }

a.instagram {
  color: black; }

.text-locale-picker {
  color: var(--color-accent); }

.text-locale-picker option {
  color: #000000; }

.vbar {
  background-color: var(--color-accent-lightest);
  width: 1px;
  display: inline-block;
  vertical-align: middle; }
  .vbar.footer {
    border-color: var(--color-accent-lightest);
    height: 12px;
    margin-left: 10px; }

@media screen and (max-width: 768px) {
  .checkoutStepsHide {
    display: none !important; }
  .checkoutStepShow {
    display: flex !important; } }

.Overlay:hover {
  opacity: .5; }

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  content: "";
  background: linear-gradient(0deg, var(--color-secondary), var(--color-secondary)); }

.Overlay.SelectedCard {
  opacity: .5;
  background: linear-gradient(0deg, var(--color-secondary), var(--color-secondary)); }

.NumberCard {
  position: absolute; }

.customFlexCardAlign {
  align-items: flex-start; }

#addRecipientButton, #removeRecipientButton {
  height: 2em;
  margin-left: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
  padding-top: 0;
  padding-bottom: 0; }

@media screen and (max-width: 767px) {
  #addRecipientButton, #removeRecipientButton {
    margin-bottom: 2em; } }

#recipientLimit {
  height: 2em;
  padding-right: 1em;
  padding-left: 1em;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -0.45em; }

.recipientTextBoxGroup {
  width: 100%; }

.buttonGroupRecipientList {
  text-align: center; }

.notAllowedPricepoint {
  cursor: not-allowed; }

.notAllowedBuyNowButton {
  display: none !important; }

.hideAddonPPs {
  display: none; }

#i4goFrame {
  width: 640px;
  padding-top: 81px; }

@media screen and (max-width: 500px) {
  #i4goFrame {
    width: 388px;
    padding: 0; } }

.modal-overlay {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; }

.modal-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 400px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto; }

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer; }

@media screen and (min-width: 768px) {
  .checkoutBox {
    display: flex; } }

/* Hide empty images */
img[src=""],
img:not([src]) {
  display: none; }
